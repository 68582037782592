/**
 * NOTE: There's a big ol' stanky bug with styled-components where dynamically
 * updating styles based on prop changes rerenders the entire stylesheet,
 * including font-face declarations.
 *
 * Render this as separate CSS so it will be added separately from the
 * styled-components tag.
 *
 * https://github.com/styled-components/styled-components/issues/400
 */

/** Common Sofia Pro weights: 300 (light), 400 (regular), 700 (bold) */

@font-face {
  font-family: 'Calibre';
  src: url('fonts/CalibreWeb-Regular.eot');
  src: url('fonts/CalibreWeb-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/CalibreWeb-Regular.woff2') format('woff2'),
    url('fonts/CalibreWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('fonts/CalibreWeb-RegularItalic.eot');
  src: url('fonts/CalibreWeb-RegularItalic.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/CalibreWeb-RegularItalic.woff2') format('woff2'),
    url('fonts/CalibreWeb-RegularItalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Calibre';
  src: url('fonts/CalibreWeb-Semibold.eot');
  src: url('fonts/CalibreWeb-Semibold.eot?#iefix') format('embedded-opentype'),
    url('fonts/CalibreWeb-Semibold.woff2') format('woff2'),
    url('fonts/CalibreWeb-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url('fonts/CalibreWeb-SemiboldItalic.eot');
  src: url('fonts/CalibreWeb-SemiboldItalic.eot?#iefix')
      format('embedded-opentype'),
    url('fonts/CalibreWeb-SemiboldItalic.woff2') format('woff2'),
    url('fonts/CalibreWeb-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
